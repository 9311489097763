import React, { Component } from "react";

class Certificates extends Component {
  render() {
    if (this.props.sharedCertificates && this.props.resumeBasicInfo) {
      var sectionName = this.props.resumeBasicInfo.section_name.certificates;
      var certificates = this.props.sharedCertificates.icons.map(function (certificates, i) {
        return (
          <li className="list-inline-item mx-3" key={i}>
            <span>
              <div className="text-center certificate-tile">
                <i className={certificates.class} style={{ fontSize: "220%" }}>
                  <p
                    className="text-center"
                    style={{ fontSize: "30%", marginTop: "4px" }}
                  >
                    {certificates.name}
                  </p>
                </i>
              </div>
            </span>
          </li>
        );
      });
    }

    return (
      <section id="certificate">
        <div className="col-md-12">
          <div className="col-md-12">
            <h1 className="section-title">
              <span className="text-black">{sectionName}</span>
            </h1>
          </div>
          <div className="col-md-12 text-center">
            <ul className="list-inline mx-auto certificate-icon">{certificates}</ul>
          </div>
        </div>
      </section>
    );
  }
}

export default Certificates;
