import React, { Component } from 'react';
import '../css/Chatbot.css';
import Typewriter from 'typewriter-effect';
import GeminiService from '../services/GeminiService'; // Import the service

class Chatbot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInput: '',
      messages: [{ text: 'Hey! How can I help you?', sender: 'bot' }],
    };
    this.messagesEndRef = React.createRef();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    if (this.messagesEndRef.current) {
      this.messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  handleInputChange = (e) => {
    this.setState({ userInput: e.target.value });
  };

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.handleSend();
    }
  };

  handleSend = async () => {
    const { userInput } = this.state;
    if (userInput.trim()) {
      this.setState((prevState) => ({
        messages: [...prevState.messages, { text: userInput, sender: 'user' }],
        userInput: '',
      }));

      const botResponse = await GeminiService.getBotResponse(userInput);
      this.setState((prevState) => ({
        messages: [...prevState.messages, { text: botResponse, sender: 'bot' }],
      }));
    }
  };

  formatText = (text) => {
    // Replace bold syntax
    text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    // Replace italic syntax
    text = text.replace(/\*(.*?)\*/g, '<em>$1</em>');
    // Replace newlines with <br> tags
    text = text.replace(/\n/g, '<br>');
    return text;
  };

  render() {
    const { isOpen, onClose } = this.props;
    const { userInput, messages } = this.state;

    return (
      <div className={`chatbot-modal ${isOpen ? 'active' : ''}`}>
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title">Keshav's Assistant</h3>
            <button type="button" className="close-button" onClick={onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div id="chatbot">
              <div className="messages">
                {messages.map((msg, index) => (
                  <div key={index} className={`message ${msg.sender}`}>
                    {msg.sender === 'bot' ? (
                      <div className="typewriter-container">
                        <Typewriter
                          options={{
                            autoStart: true,
                            loop: false,
                            cursor: '',
                            delay: 5,
                          }}
                          onInit={(typewriter) => {
                            const formattedText = this.formatText(msg.text);
                            typewriter
                              .typeString(msg.text)
                              .callFunction(() => {
                                const el = document.querySelector(`.message.bot:nth-of-type(${index + 1}) .typewriter-container`);
                                if (el) el.innerHTML = formattedText;
                              })
                              .start();
                          }}
                        />
                      </div>
                    ) : (
                      msg.text
                    )}
                  </div>
                ))}
                <div ref={this.messagesEndRef} />
              </div>
            </div>
            <div className="input-container">
              <input
                type="text"
                className="chat-input"
                placeholder="Type your message here..."
                value={userInput}
                onChange={this.handleInputChange}
                onKeyDown={this.handleKeyPress}
              />
              <button className="send-button" onClick={this.handleSend}>
                Send
              </button>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="close-button" onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Chatbot;