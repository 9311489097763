// src/services/geminiService.js

const API_URL = 'https://kshvgeminiapi.azurewebsites.net';
class GeminiService {
  constructor() {
    this.messageHistory = [];
  }

  async getBotResponse(userInput) {
    try {
      // Add the user's input to the message history
      this.messageHistory.push({ role: 'user', text: userInput });

      const requestBody = {
        messages: this.messageHistory
      };


      const response = await fetch(API_URL+'/api/gemini/getBotResponseWithHistory', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      const botResponse = data.candidates[0].content.parts[0].text;

      // Add the bot's response to the message history
      this.messageHistory.push({ role: 'model', text: botResponse });

      return botResponse;
    } catch (error) {
      return 'Sorry, I am unable to respond at the moment.';
    }
  }
}

export default new GeminiService();