import React, { Component } from "react";
import { Icon } from "@iconify/react";
import angularIcon from "@iconify/icons-logos/azure-icon";
import reactIcon from "@iconify/icons-logos/react";
import vueIcon from "@iconify/icons-logos/c-sharp";
import k8s from "@iconify/icons-logos/kubernetes"
import ReactHtmlParser from "react-html-parser";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFullContent: false,
    };
  }

  toggleShowMore = () => {
    this.setState((prevState) => ({
      showFullContent: !prevState.showFullContent,
    }));
};

  render() {
    if (this.props.sharedBasicInfo) {
      var profilepic = "images/" + this.props.sharedBasicInfo.image;
    }
    if (this.props.resumeBasicInfo) {
      var sectionName = this.props.resumeBasicInfo.section_name.about;
      var hello = this.props.resumeBasicInfo.description_header;
      var about = this.props.resumeBasicInfo.description;
      var jobstartdate = new Date("2015-09-04")
      var now = new Date()
      const dateDifferenceInMonths = (dateInitial, dateFinal) =>
        Math.max(
          (dateFinal.getFullYear() - dateInitial.getFullYear()) * 12 +
            dateFinal.getMonth() - dateInitial.getMonth(),
    0);
      let yoe = dateDifferenceInMonths(jobstartdate,now)/12;
      yoe=Math.round(yoe)
      about=about.replace("yoe",yoe.toString())
    }

    const { showFullContent } = this.state;

    return (
      <section id="about">
        <div className="col-md-12">
          <h1 style={{ color: "black" }}>
            <span>{sectionName}</span>
          </h1>
          <div className="row center mx-auto mb-5">
            <div className="col-md-4 mb-5 center">
              <div className="polaroid">
                <span style={{ cursor: "auto" }}>
                  <img
                    height="250px"
                    src={profilepic}
                    alt="Avatar placeholder"
                  />
                  <Icon
                    icon={angularIcon}
                    style={{ fontSize: "250%", margin: "9% 5% 0 5%" }}
                  />
                  <Icon
                    icon={reactIcon}
                    style={{ fontSize: "250%", margin: "9% 5% 0 5%" }}
                  />
                  <Icon
                    icon={vueIcon}
                    style={{ fontSize: "250%", margin: "9% 5% 0 5%" }}
                  />
                  <Icon
                    icon={k8s}
                    style={{ fontSize: "250%", margin: "9% 5% 0 5%" }}
                  />
                </span>
              </div>
            </div>

            <div className="col-md-8 center">
              <div className="col-md-10">
                <div className="card">
                  <div className="card-header">
                    <span
                      className="iconify"
                      data-icon="emojione:red-circle"
                      data-inline="false"
                    ></span>{" "}
                    &nbsp;{" "}
                    <span
                      className="iconify"
                      data-icon="twemoji:yellow-circle"
                      data-inline="false"
                    ></span>{" "}
                    &nbsp;{" "}
                    <span
                      className="iconify"
                      data-icon="twemoji:green-circle"
                      data-inline="false"
                    ></span>
                  </div>
                  <div
                     className={`card-body font-trebuchet text-justify ml-3 mr-3 ${
                      showFullContent ? "card-body-wrapper" : "card-body-wrapper closed"
                    }`}
                    style={{
                      height: "auto",
                      fontSize: "132%",
                      lineHeight: "200%",
                    }}
                  >
                    <br />
                    <span className="wave">{hello} :) </span>
                    <br />
                    <br />
                    {/* Check if 'about' is defined before accessing 'substring' */}
                    {showFullContent ? (
                      <div>
                        {ReactHtmlParser(about)}
                        <button
                          onClick={this.toggleShowMore}
                          className="btn btn-primary"
                        >
                          Show Less
                        </button>
                      </div>
                    ) : (
                      <div>
                        {about && about.length > 300 ? (
                          <div>
                            { ReactHtmlParser(about.substring(0, 300))}
                            <button
                              onClick={this.toggleShowMore}
                              className="btn btn-primary"
                            >
                              Show More
                            </button>
                          </div>
                        ) : (
                          ReactHtmlParser(about)
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
